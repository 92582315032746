@import '@/_styles/import';
.control {
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.arrowButton {
  width: 44px;
  height: 44px;
  margin-bottom: 10px;
  padding: 0;

  color: $white;

  background: none;
  border: 2px solid transparent;

  fill: currentcolor;

  > * {
    display: grid;
    place-content: center;
  }

  &:focus {
    border-color: $white;
    border-radius: 50%;
  }

  &.prev {
    z-index: 1;
    left: 5px;
    transform: rotate(180deg);
  }

  &.next {
    right: 5px;
  }

  @include media-breakpoint-up(sm) {
    &.prev {
      left: 15px;
    }

    &.next {
      right: 15px;
    }
  }
}

.dot {
  width: 20px;
  height: 100%;
  padding: 0;

  background: none;
  border: 0;

  .innerDot {
    display: block;
    width: 100%;
    height: 100%;

    &::after {
      content: '';

      position: relative;
      top: 50%;

      display: block;

      width: 100%;
      height: 2px;

      background-color: rgb(255 255 255 / 80%);

      transition: background-color 300ms;
    }
  }

  &:focus-visible {
    outline: none;

    .innerDot::after {
      border-radius: 2px;
      outline: 2px solid $white;
      outline-offset: 2px;
      box-shadow: 0 0 0 2px $black;
    }
  }
}

.dots {
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  gap: 4px;

  margin: 0;
  padding: 0;

  list-style: none;

  :global(.slick-active) .innerDot::after {
    background-color: $vv-red;
  }
}

.fullscreenButton {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;

  overflow: visible;
  display: grid;
  place-content: center;

  width: 44px;
  height: 44px;
  margin: 0;
  padding: 0;

  background: transparent;
  border: 2px solid transparent;
  border-radius: 50%;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  &:focus-visible {
    border-color: $white;
  }

  @include media-breakpoint-down(sm) {
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
  }
}

.fitImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

:global(.brass) :global(.slick-active) .innerDot::after {
  background-color: $brass;
}

.root {
  position: relative;

  :global(.Icon),
  :global(.Icon) > svg {
    display: block;
  }
}

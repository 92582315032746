@import '@/_styles/import';
$container-width: 1180px;
$card-gap: 18px;

.root {
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;

  width: ($container-width - ($card-gap * 3)) / 4;
  height: 90px;

  background: var(--promo-color-4, $white);
  border-radius: 8px;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 85%;
  height: 100%;
  padding: 12px;
}

.title {
  @include font(rem(17), $weight: 700, $line-height: rem(20), $color: var(--promo-color-1, $black-violet));

  margin: 0;
  text-overflow: ellipsis;
}

.prices {
  display: flex;
  align-items: center;

  .new {
    @include font(rem(20), $weight: 700, $line-height: rem(25), $color: $red);
  }

  .old {
    margin-left: 5px;

    @include font(rem(15), $weight: 500, $line-height: rem(20), $color: $gray-light);

    text-decoration: line-through;
  }
}

.priceType {
  @include font(rem(17), $weight: 400, $line-height: rem(20), $color: $red);

  margin: 0;
}

.cta {
  width: 15%;
  height: 100%;

  :global(.btn) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 12px;

    border-radius: 0 8px 8px 0;

    :global(.Icon) {
      width: 24px;
      height: 24px;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

@import '@/_styles/import';
.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    z-index: 0;
  }
}

.headingSpace {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.backButtonContainer {
  position: relative;

  display: flex;
  align-items: center;
  order: 3;

  width: 90%;
  margin: 10px auto 20px;

  @include media-breakpoint-up(xl) {
    max-width: map-get($grid-breakpoints, 'xl');
    margin: 30px auto auto;
  }
}

.header {
  order: -1;
  margin-top: 30px;
  font-size: rem(20);
  font-weight: 500;
}

.description {
  @include font($size: rem(15), $align: center);

  order: 0;
  width: 70%;

  @include media-breakpoint-up(md) {
    width: 60%;
    font-size: rem(17);
  }
}

@include media-breakpoint-up(lg) {
  .backButtonContainer {
    order: 1;
    margin: 20px auto auto;
  }

  .header {
    flex: unset;
    order: 2;
    margin-top: -35px;
  }

  .description {
    order: 3;
  }

  .services {
    order: 4;
  }
}

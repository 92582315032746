@import '@/_styles/import';
.root {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  margin: 0 20px 30px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    gap: 12px;
  }
}

.heading {
  margin: 0;
  font-size: rem(17);

  @include media-breakpoint-down(lg) {
    font-size: rem(15);
    text-align: center;
  }
}

.sort {
  all: unset;

  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;

  height: 40px;
}

.filterItem {
  all: unset;
}

.filter {
  @include font($size: rem(14), $color: $black);

  cursor: pointer;

  display: grid;
  place-content: center;

  width: 100px;
  min-width: 32%;
  height: 40px;

  background-color: $white;
  border: 1px solid $vv-red;
  border-radius: rem(20);

  transition: background-color 150ms;

  &[aria-checked='true'] {
    color: $white;
    background-color: $vv-red;
  }

  &:disabled {
    pointer-events: none;
    background-color: rgb($grey-scale, 10%);
  }

  @include media-breakpoint-up(md) {
    // iOS stuck hover state, so we should use it
    &:hover {
      color: $white;
      background-color: darken($vv-red, 2%);
    }
  }
}

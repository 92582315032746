@import '@/_styles/import';
$padding-1: 8px;
$padding-2: 16px;
$padding-3: 24px;

.root {
  cursor: pointer;

  display: grid;
  grid-template-columns: 1fr min-content 60px;

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr min-content 70px;
  }

  align-items: stretch;

  height: 131px;

  background-color: $white;
  border: 1px solid $gray-205;
  border-radius: 8px;
  box-shadow: 0 2px 20px 0 $black-008;
}

.content {
  display: flex;
  align-items: center;

  text-align: left;

  background: transparent;
  border: 0;
}

.cover {
  $side: 65px;
  $side-md-plus: 96px;

  display: flex;
  flex-basis: calc(#{$side} + (#{$padding-2} * 2));
  justify-content: center;

  .image {
    width: $side;
    height: $side;
  }

  @include media-breakpoint-up(md) {
    flex-basis: calc(#{$side-md-plus} + (#{$padding-2} * 2));

    .image {
      width: $side-md-plus;
      height: $side-md-plus;
    }
  }
}

.details {
  $width: 190px;

  flex-basis: $width;
  flex-grow: 1;

  > * {
    margin: 0;
  }

  @include media-breakpoint-up(sm) {
    margin-left: $padding-1;
  }

  .heading {
    @include font($size: rem(15), $weight: 700, $line-height: 1.33, $color: $black);

    @include media-breakpoint-up(sm) {
      @include font($size: rem(17), $line-height: 1.6);
    }
  }

  .price {
    margin-top: 2px;

    @include font($size: rem(15), $weight: 700, $line-height: 1.33, $color: $grey-text);

    @include media-breakpoint-up(sm) {
      @include font($size: rem(15));
    }
  }

  .description {
    margin-top: $padding-1;

    @include font($size: rem(12), $line-height: 1.33, $color: $gray-505);

    @include media-breakpoint-up(sm) {
      @include font($size: rem(15), $line-height: 1.6);
    }
  }
}

.divider {
  $wide: 0.75px;
  $padding-vertical: $padding-3;

  width: $wide;
  height: calc(100% - ($padding-vertical * 2));
  margin: $padding-vertical 0 $padding-vertical $padding-1;
  background-color: $gray-050;
}

.toggleArea {
  display: flex;
  place-content: center;

  &:focus {
    .circleSelect {
      border: 2px solid $white;
      border-radius: 15px;
      outline: 2px solid $black;
    }
  }

  :global(.Icon) {
    $side: 25px;

    cursor: pointer;

    position: absolute;

    width: $side;
    height: $side;
    margin: 0;

    color: $red;

    &.showing :global {
      animation:
        fadeIn 0.5s,
        spin-show 0.5s cubic-bezier(0.68, -0.6, 0.42, 1.91);
      animation-fill-mode: both;
    }

    &.hiding :global {
      animation:
        fadeOut 0.5s,
        spin-hide 0.5s;
      animation-fill-mode: both;
    }
  }
}

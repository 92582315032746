@import '@/_styles/import';
.root {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr minmax(min-content, 2fr);

    .backgroundImage {
      order: -1;
    }
  }

  @include media-breakpoint-down(xs) {
    grid-template-rows: 1fr minmax(min-content, 4fr);
  }
}

.textContent {
  margin: 0 auto;
  padding: 30px 50px;

  @include media-breakpoint-down(md) {
    padding: 30px 25px;
  }

  @include media-breakpoint-down(sm) {
    gap: 10px;
    padding: 10px 20px;
  }
}

.containerWithGaps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;

  text-align: center;

  @include media-breakpoint-down(sm) {
    gap: 10px;
  }
}

.logo {
  height: 60px;
}

.title {
  @include font($size: 22px, $weight: bold, $line-height: 31px);
}

.subTitle {
  @include font($size: 20px, $weight: normal, $line-height: 25px);
}

.description {
  text-align: left;

  @include font($size: 15px, $line-height: 34px);

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  .textCenter {
    text-align: center;
  }
}

.backgroundImage {
  background-color: $gray-010;
  background-image: var(--desktop-image);
  background-position: center;
  background-size: cover;

  @include media-breakpoint-down(md) {
    background-image: var(--mobile-image);
  }
}

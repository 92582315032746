@import '@/_styles/import';
.list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }
}

.item {
  flex-basis: calc(50% - 8px);

  @include media-breakpoint-down(xs) {
    flex-basis: unset;
  }
}

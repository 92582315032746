@import '@/_styles/import';
.imageContainer {
  position: relative;
  opacity: 0.5;
}

.imageContent {
  position: absolute;
  z-index: 0;
  left: 0;
  width: 100%;

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }
}

.imageGradient {
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-size: cover;
}

.pageWrapper {
  height: rem(212);

  @media (min-width: $container-sm-width) and (max-width: $container-lg1-width) {
    height: rem(360);
  }

  @media (min-width: $container-lg1-width) and (max-width: $container-lg2-width) {
    height: rem(500);
  }

  @media (min-width: $container-lg2-width) {
    height: rem(550);
  }

  @media (max-width: $container-xxxs-width) {
    height: rem(200);
  }
}

@import '@/_styles/import';
.root {
  display: grid;
  place-items: center;
  margin-block: 28px;

  @include media-breakpoint-down(md) {
    margin-block: 50px;
  }

  @include media-breakpoint-down(xs) {
    margin-block: 40px;
  }
}

.trigger {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;

  padding: 0;

  background: transparent;
  border: 0;

  .icon {
    position: relative;
    width: 50px;
    margin-right: -13px;
  }

  .text {
    position: relative;

    overflow: hidden;

    padding: 1px 20px;

    text-align: left;

    background: #fcf2f2;
    border-radius: 2px 30px 30px 2px;

    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: -58px;
      transform: translateY(-50%);

      width: 70px;
      height: 70px;

      background: $background-color;
      border-radius: 50%;
    }

    .subheading {
      margin: 0;

      @include font($size: rem(20), $line-height: 1.25, $color: $black);
    }

    .cta {
      @include font($size: rem(15), $color: $grey-scale);

      :global(.Icon) {
        width: 20px;
        height: 20px;
        margin-left: 4px;
      }
    }
  }
}

.dialogCloseButton {
  top: 20px;
  right: 20px;
  left: unset;
  background: rgba($white, 0.9);
}

.dialog {
  overflow-y: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;

  width: 80vw;
  min-width: 350px;
  max-width: 850px;
  max-height: 95vh;

  .picture {
    background: center / cover var(--desktop-image);

    .logo {
      display: block;
      min-height: 50px;
      max-height: 200px;
      margin: 20% auto;
    }
  }

  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    grid-template-rows: auto minmax(min-content, 2fr);

    .picture {
      order: -1;
      background: center / cover var(--mobile-image);
    }
  }

  @include media-breakpoint-down(xs) {
    grid-template-rows: auto minmax(min-content, 4fr);

    .picture .logo {
      margin: 15% auto;
    }
  }

  .textContent {
    @include font($size: rem(17), $color: $black);

    display: grid;
    gap: 10px;
    place-content: center;

    margin: 0 auto;
    padding: 50px;

    @include media-breakpoint-down(md) {
      padding: 30px 25px;
    }

    @include media-breakpoint-down(sm) {
      gap: 10px;
      padding: 10px 20px;
    }

    .heading {
      font-size: rem(30);
    }

    .link {
      font-weight: 700;
    }
  }
}

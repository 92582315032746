@mixin make-color($color, $background, $border) {
  @if $color {
    color: $color;
  }

  @if $background {
    @include gradient-bg($background);
  }

  @if $border {
    border: 2px solid $border;
  }
}

@mixin make-button(
  $background,
  $border,
  $color: color-yiq($background),
  $hover-background: darken($background, 7.5%),
  $hover-border: darken($border, 10%),
  $hover-color: color-yiq($hover-background),
  $active-background: darken($hover-background, 10%),
  $active-border: darken($hover-border, 12.5%),
  $focus-color: $background,
  $disabled-background: $btn-base-bg-disabled,
  $disabled-border: $btn-base-border-disabled,
  $disabled-color: $btn-base-color-disabled
) {
  @include make-color($color, $background, $border);
  @include box-shadow($btn-box-shadow);

  position: relative;
  padding: 10px 30px;

  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    z-index: 1;
    inset: 0;

    width: 100%;
    height: 100%;

    appearance: inherit;
    border: 2px solid transparent;
  }

  &:focus-visible,
  &.focus {
    color: $focus-color;
  }

  @include hover {
    @include make-color($hover-color, $hover-background, $hover-border);
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    @include make-color($disabled-color, $disabled-background, darken($disabled-border, 10%));

    pointer-events: none;
  }

  &:not(:disabled, .disabled):active,
  &:not(:disabled, .disabled).active,
  .show > &.dropdown-toggle {
    color: $hover-color;
    background: $active-background;

    &:focus {
      color: $hover-color;
    }
  }
}

@mixin reset-button() {
  all: unset;
  outline: revert;
}

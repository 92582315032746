@import '@/_styles/import';
.overlay {
  position: fixed;
  z-index: $z-index-flyout;
  inset: 0;

  background-color: rgba($black, 0.8);

  // TODO: fix animation on close
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.fixedContainer {
  position: fixed;
  z-index: $z-index-flyout;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  overflow: visible;
  display: grid;

  // TODO: fix animation on close
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.content {
  overflow: auto;

  max-width: 900px;
  min-height: 200px;
  max-height: 90vh;

  background-color: $white;
  border-radius: 6px;
  box-shadow:
    hsl(206deg 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206deg 22% 7% / 20%) 0 10px 20px -15px;

  /* stylelint-disable-next-line length-zero-no-unit */
  @include scrollbar($size: 6px, $outline: 0px);
}

.content:focus {
  outline: none;
}

.close {
  position: absolute;
  top: 10px;
  left: 10px;

  display: grid;
  place-items: center;

  width: 28px;
  height: 28px;
  padding: 6px;

  color: inherit;

  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;

  fill: currentcolor;

  &:hover {
    filter: opacity(0.75);
  }

  &:focus-visible {
    border-color: $white;
  }
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    transform: translate(-50%, -48%) scale(0.96);
    opacity: 0;
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}

@import '@/_styles/import';
.item {
  @mixin small-item {
    box-shadow: 0 0 1px 1px $gray-510;
    padding: 16px;
    display: grid;
    grid-template-areas:
      'item-thumb item-title'
      'item-thumb item-price'
      'item-thumb item-desc'
      'item-buttons item-buttons';

    grid-template-columns: 81px 1fr;
    grid-template-rows: auto auto 1fr;

    .itemTick {
      top: -8px;
      right: -8px;
      width: 24px;
      height: 24px;
      padding: 5px;
      border-radius: 12px;
      background-color: $tropical-blue-2;

      :global(.Icon) {
        @include icon($width: 14px, $height: 14px, $color: $white);
      }
    }

    .itemTopPick {
      top: 63px;
      left: 16px;
      padding: 0;
      width: 65px;
      background-color: $light-green1;
      border-radius: 0 0 4px 4px;
      display: block;
      text-align: center;

      @include font($size: rem(13), $line-height: 20px, $weight: 500, $color: $black-violet);

      :global(.Icon) {
        display: none;
      }
    }

    .itemPrice {
      @include font($size: rem(13), $line-height: rem(20), $weight: 700, $color: $black-violet);

      margin-bottom: 4px;
      padding: unset;
      position: unset;
      top: unset;
      left: unset;
      background-color: unset;
    }

    .itemThumbnail {
      height: unset;
      width: fit-content;

      .itemBigThumbnail {
        display: none;
      }

      .itemSmallThumbnail,
      .itemSmallThumbnail :global(.loading-skeleton) {
        display: block;
        width: 65px;
        height: 67px;
        border-radius: 4px;
      }
    }

    .itemTitle {
      padding: 0;

      @include font($size: rem(15), $line-height: rem(24), $weight: 500, $color: $black);
    }

    .itemDescription {
      padding: 0;

      @include font($size: rem(12), $line-height: rem(15.84), $weight: 400, $color: $lightgrey2);
    }

    .itemButtons {
      gap: 12px;
      padding: 12px 0 0;
      margin-top: 16px;
      border-top: 1px solid $lightwhite;

      .itemAddButton {
        border-width: 1px;
      }

      .itemAddButton,
      .itemDetailsButton {
        white-space: nowrap;
        flex-basis: calc(50% - 12px);
      }

      .itemDetailsButton,
      .itemManageButton {
        @include font($size: rem(13), $line-height: 32px, $weight: 500);
      }
    }

    &._itemAdded {
      background-color: $white;
      box-shadow: 0 0 1px 1px $gray-010;

      .itemSmallThumbnail {
        opacity: 0.6;
        filter: grayscale(1);
      }

      .itemPrice {
        color: $blue-purple;
        background-color: unset;
      }

      .itemManageButton {
        flex: 1;
        color: $lightgrey2;
      }
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  width: 275px;
  background-color: $white;
  box-shadow: 0 1px 8px 0 #0000000d;
  border-radius: 8px;

  .itemTick {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 15px;
    background-color: $tropical-blue-2;

    :global(.Icon) {
      @include icon($width: 20px, $height: 20px, $color: $white);
    }
  }

  .itemTopPick {
    position: absolute;
    display: flex;
    top: 14px;
    left: 14px;
    padding: 0 8px;
    background-color: $light-green1;
    align-items: center;
    border-radius: 15px;

    @include font($size: rem(15), $line-height: 30px, $weight: 500, $color: $black-violet);

    :global(.Icon) {
      @include icon($width: 16px, $height: 16px, $color: $vv-red);

      padding-right: 20px;
    }
  }

  .itemPrice {
    grid-area: item-price;
    position: absolute;
    top: 151px;
    left: 0;
    padding: 0 12px;
    background-color: $black-violet;

    @include font($size: rem(15), $line-height: 32px, $weight: 500, $color: $white);
  }

  .itemThumbnail {
    grid-area: item-thumb;
    height: 183px;

    .itemBigThumbnail,
    .itemBigThumbnail :global(.loading-skeleton) {
      border-radius: 8px 8px 0 0;
      display: block;
      width: 100%;
      height: 183px;
      object-fit: cover;
    }

    .itemSmallThumbnail {
      display: none;
    }
  }

  .itemTitle {
    grid-area: item-title;

    @include font($size: rem(20), $line-height: rem(26.4), $weight: 700, $color: $black);

    padding: var(--addon-item-padding) var(--addon-item-padding) 0 var(--addon-item-padding);
  }

  .itemDescription {
    grid-area: item-desc;

    @include font($size: rem(15), $line-height: rem(24.6), $weight: 400, $color: $gray-light);

    padding: 0 var(--addon-item-padding);
  }

  .itemButtons {
    grid-area: item-buttons;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: var(--addon-item-padding);

    .itemAddButton {
      padding: 0 20px;

      @include font($size: rem(11), $line-height: 28px, $weight: 700, $spacing: 20%);
    }

    .itemDetailsButton {
      @include reset-button();
      @include font($size: rem(14), $line-height: 32px, $weight: 400, $color: $vv-red);

      text-decoration: underline;
      text-align: center;
      outline-color: $black;
      cursor: pointer;

      :global(.loading-skeleton) {
        width: 64px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    .itemManageButton {
      @include reset-button();
      @include font($size: rem(14), $line-height: 32px, $weight: 400, $color: $lightgrey2);

      text-decoration: underline;
      text-align: center;
      outline-color: $black;
      cursor: pointer;
      display: none;

      :global(.loading-skeleton) {
        width: 164px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  &._itemAdded {
    background-color: $lightwhite;
    box-shadow: 0 0 1px 1px $gray-010;

    .itemTick {
      display: block;
    }

    .itemPrice {
      background-color: $blue-purple;
      width: 100%;
    }

    .itemTitle {
      color: $lightgrey2;
    }

    .itemDescription {
      color: $lightgrey2;
    }

    .itemButtons {
      .itemAddButton,
      .itemDetailsButton {
        display: none;
      }

      .itemManageButton {
        display: block;
      }
    }
  }

  &._smallItem {
    @include small-item();
  }

  @include media-breakpoint-down(md) {
    width: unset;
    flex-basis: calc(50% - 12px);
  }

  @include media-breakpoint-down(xs) {
    @include small-item();
  }
}

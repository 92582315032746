@mixin icon($width: 24px, $height: 24px, $color: null) {
  &,
  svg {
    height: $width;
    width: $height;
    display: block;
  }

  @if $color != null {
    svg {
      fill: $color;
    }
  }
}

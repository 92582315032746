@import '@/_styles/import';
.root {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--cabin-card-gap);
  align-items: center;

  width: 100%;
  max-width: map-get($grid-breakpoints, 'xl');
  margin: 0 auto;
  margin-bottom: var(--cabin-card-gap);
  padding: 0 10px;
}

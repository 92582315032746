@import '@/_styles/import';
.root {
  display: flex;
  align-items: center;
  justify-content: center;

  width: rem(25);
  height: rem(41);
  padding: 0;

  background: transparent;
  border: 0;

  transition: transform 200ms;
}

.up {
  transform: rotate(270deg);

  svg {
    margin: -2px 0 0 -1px;

    @include media-breakpoint-down(md) {
      margin: -4px 0 0 -2px;
    }

    @include media-breakpoint-down(sm) {
      margin: -1px 0 0 -2px;
    }
  }
}

.down {
  transform: rotate(90deg);

  svg {
    margin: -2px 0 0 -2px;

    @include media-breakpoint-down(md) {
      margin: -3px 0 0 -2px;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 0 0 -2px;
    }
  }
}

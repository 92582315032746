@import '@/_styles/import';
.layout {
  --addon-item-padding: 20px;

  background-color: $itinerary-background;
  flex: 1;
}

.root {
  position: relative;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1280px;

  @include media-breakpoint-down(md) {
    max-width: 688px;
  }

  @include media-breakpoint-down(xs) {
    max-width: unset;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50px;
  margin: 25px 0;

  h1 {
    max-width: 704px;
    text-align: center;
    margin: 0;

    @include font($size: rem(20), $color: $black, $line-height: rem(25), $weight: 500);

    @include media-breakpoint-down(md) {
      @include font($size: rem(17), $color: $black, $line-height: rem(25), $weight: 500);
    }
  }

  .bypassButton {
    @include reset-button();

    display: flex;
    align-items: center;
    position: absolute;
    right: 0;

    @include font($size: rem(15), $color: $black, $line-height: 24px, $weight: 400);

    background-color: $white;
    border-radius: 20px;
    padding: 8px 16px;

    outline-color: $black;
    box-shadow: 0 1px 10px 0 #0000000d;
    cursor: pointer;

    :global(.Icon) {
      @include icon($color: $vv-red);

      padding-left: 6px;
    }

    @include media-breakpoint-down(md) {
      position: unset;
      right: unset;
      background-color: unset;
      padding: unset;

      @include font($size: rem(14), $color: $vv-red, $line-height: 20px, $weight: 400);

      text-decoration: underline;

      :global(.Icon) {
        display: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-down(md) {
    gap: 12px;
  }
}

.list {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 120px;

  @include media-breakpoint-down(md) {
    gap: 24px;
  }

  @include media-breakpoint-down(xs) {
    flex-flow: column nowrap;
    gap: 18px;
    padding-bottom: 150px;
  }
}

.fakeCheckoutLineDescription {
  visibility: hidden;
}

.checkoutBar {
  z-index: 200;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: $black-violet-95;
  color: $white;
  padding: 16px 0;

  .root {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      gap: 16px;
    }
  }

  .checkoutBarContent {
    flex-basis: 60%;

    @include media-breakpoint-down(xs) {
      flex-basis: unset;
      width: 100%;
    }
  }

  .checkoutLineTitle {
    grid-area: checkout-line-title;

    @include font($size: rem(15), $color: $white, $line-height: rem(24), $weight: 500);
  }

  .checkoutLinePrice {
    grid-area: checkout-line-price;

    @include font($size: rem(15), $color: $white, $line-height: rem(24), $weight: 500);

    padding-left: 16px;
  }

  .checkoutLineDescription {
    grid-area: checkout-line-descr;

    @include font($size: rem(12), $color: $gray-410, $line-height: rem(15.84), $weight: 500);
  }

  .checkoutLine {
    display: grid;
    width: 100%;
    grid-template-areas:
      'checkout-line-title checkout-line-price'
      'checkout-line-descr checkout-line-price';

    grid-template-columns: 1fr auto;
  }

  .checkoutButton {
    flex-basis: 30%;
    max-width: 336px;

    @include media-breakpoint-down(xs) {
      flex-basis: unset;
      max-width: unset;
    }
  }
}

.popup {
  width: 70vw;
  max-width: 900px;
  max-height: 90vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 70px 90px;

  .popupTitle,
  .popupDescription {
    text-align: center;
  }

  .popupTitle {
    padding-bottom: 20px;

    @include font($size: rem(30), $color: $black, $line-height: rem(36), $weight: 700);
  }

  .popupDescription {
    margin-bottom: 50px;

    @include font($size: rem(18), $color: $lightgrey2, $line-height: rem(26), $weight: 400);
  }

  .popupButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 40px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 60px;
    padding-right: 60px;
    max-width: unset;
    width: 70vw;

    .popupButtons {
      flex-direction: column;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-left: 35px;
    padding-right: 35px;
    width: 100%;
    max-width: unset;
  }
}

.popupClose {
  @include reset-button();

  position: absolute;
  right: 16px;
  top: 16px;

  :global(.Icon) {
    @include icon($color: $black, $width: 30px, $height: 30px);
  }

  @include media-breakpoint-down(xs) {
    right: 29px;
  }
}

.popupContainer {
  @include media-breakpoint-down(xs) {
    width: 100%;
    padding: 0 15px;
  }
}

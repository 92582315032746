@import '@/_styles/import';
.content {
  overflow: hidden;

  width: 100vw;
  max-width: unset;
  height: 100vh; /* Fallback for older browsers */
  height: 100dvh;
  max-height: unset;

  background: linear-gradient(180deg, $maroon 0%, $pink-dark 100%);
  border-radius: 0;
}

.close {
  z-index: 99;
  top: 15px;
  right: 15px;
  left: unset;

  color: $white;
}

.count {
  position: absolute;
  z-index: 99;
  top: 0;

  display: grid;
  place-content: center;

  width: 60px;
  height: 60px;

  background-color: $purple;

  @include font($size: rem(16), $weight: bold, $color: $white, $spacing: 0);

  .increment {
    position: absolute;
    top: 8px;
    left: 4px;

    width: 2ch;

    text-align: center;
  }

  .separator {
    transform: rotate(304deg);
    width: 30px;
    height: 1px;
    border-bottom: 1px solid $cabin-meta-color2;
  }

  .maxLength {
    position: absolute;
    right: 4px;
    bottom: 8px;

    width: 2ch;

    text-align: center;
  }
}

.imageContainer {
  display: grid;
  grid-template-rows: min(80vw, 80vh) 100px;
  gap: 40px;
  align-content: center;
  justify-items: center;

  height: calc(100dvh - 8px);
  margin-top: 8px;

  .image {
    align-self: flex-end;

    aspect-ratio: 3 / 2;
    max-width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .description {
    @include font($size: rem(17), $align: center, $color: $white);

    padding: 0 8px;
  }
}

.vipCategory {
  &.content {
    background: linear-gradient(180deg, $yellow-dark 0%, $black-version2 100%);
  }

  .count {
    background-color: $gray-light;
  }

  .separator {
    background-color: $gold;
    border-bottom: 1px solid $gold;
  }
}

.imageDescription {
  margin: 0 auto 20px;
  color: $white;
  text-align: center;
}

.content .arrows {
  bottom: 50%;
  translate: 0 50%;
}

@import '@/_styles/import';
.loader {
  --base-color: #{$gray-410};
  --highlight-color: #{$black-deep};

  transform: translateX(-50px);

  aspect-ratio: 1;
  width: 10px;
  margin: 10px auto;

  color: var(--base-color);

  border-radius: 50%;
  box-shadow:
    25px 0 0 2px,
    50px 0 0 0,
    75px 0 0 0;

  animation: multi-pulse 0.8s infinite alternate linear;
}

@keyframes multi-pulse {
  0% {
    box-shadow:
      25px 0 0 2px var(--highlight-color),
      50px 0 0 0,
      75px 0 0 0;
  }

  50% {
    box-shadow:
      25px 0 0 0,
      50px 0 0 2px var(--highlight-color),
      75px 0 0 0;
  }

  100% {
    box-shadow:
      25px 0 0 0,
      50px 0 0 0,
      75px 0 0 2px var(--highlight-color);
  }
}

@import '@/_styles/import';
.root {
  --box-padding-x: 48px;

  padding: 32px var(--box-padding-x);
  background-color: $white;
  border-radius: 4px;

  @include media-breakpoint-down(md) {
    --box-padding-x: 40px;
  }

  @include media-breakpoint-down(sm) {
    --box-padding-x: 24px;
  }
}

.eyebrow {
  @include font($size: rem(15), $color: $grey-text, $line-height: rem(24), $weight: 400);

  margin: 0 0 24px;
  text-transform: uppercase;

  &:has(+ .header) {
    margin-bottom: 8px;
  }
}

.header {
  @include font($size: rem(20), $color: $black, $line-height: rem(26.4), $weight: 700);

  margin: 0 0 24px;

  &:has(+ .subHeader) {
    margin-bottom: 0;
  }
}

.subHeader {
  @include font($size: rem(16), $color: $black, $line-height: rem(21.12), $weight: 500);

  margin: 4px 0 24px;
  color: $red;
  letter-spacing: 1.5%;
}

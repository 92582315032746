@import '@/_styles/import';
.root {
  --view-more-dates-inline-padding: 25px;

  position: relative;
  background-color: $white;
}

.withBorder {
  overflow-y: auto;

  width: 374px;
  height: 75vh;
  margin-bottom: 60px;

  border: 1px solid $gray-010;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 $black-006;

  ::-webkit-scrollbar {
    width: 7px;
    appearance: none;
  }
}

.ctaSlot {
  margin: 0 var(--view-more-dates-inline-padding) 40px;
}

.description ~ .ctaSlot {
  margin-top: -10px;
}

.headerImage {
  position: absolute;
  top: 0;
  right: 0;

  overflow: hidden;

  width: 144px;
  height: 144px;

  border-bottom-left-radius: 100%;
}

.titleWithImage {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.6s;

  .content {
    @include font($size: rem(32), $weight: 'bold', $line-height: rem(42));

    margin: 95px 110px 30px var(--view-more-dates-inline-padding);
  }
}

.titleWithImageSticky {
  position: sticky;
  z-index: 1;
  top: 0;
  translate: 0 rem(-84);

  width: stretch;
  height: 58px;
  margin-top: rem(-58);

  visibility: hidden;
  opacity: 0;
  background: $white;

  transition:
    opacity 0.3s,
    translate 0.3s;

  .content {
    width: 100%;
    padding: 26px var(--view-more-dates-inline-padding) 6px;
    text-align: center;

    @include font($size: rem(17), $weight: 'bold', $line-height: rem(25));
  }
}

.title {
  margin: 50px 0 10px;

  @include font($size: rem(24), $line-height: 1.5, $weight: bold);

  text-align: center;
}

.titleSticky {
  position: sticky;
  z-index: 1;
  top: 0;
  translate: 0 -84px;

  width: stretch;
  margin-top: -65px;
  padding: 20px 23px;

  visibility: hidden;
  opacity: 0;
  background: $white;
  border-bottom: solid 1px $gray-010;

  transition:
    opacity 0.3s,
    translate 0.3s;

  @include font($size: rem(17), $line-height: rem(25));
}

.hide {
  visibility: hidden;
  opacity: 0;
}

.show {
  translate: none;
  visibility: visible;
  opacity: 1;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 30px var(--view-more-dates-inline-padding) 40px;

  @include font($size: rem(17), $line-height: rem(28));

  > * {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  :global(.Icon) {
    display: grid;
    flex-shrink: 0;
    place-items: center;

    height: 28px; // Same as line-height

    fill: $grey-text;
  }
}

.totalSailingsCount {
  margin: 40px var(--view-more-dates-inline-padding);

  @include font($size: rem(17), $line-height: rem(28), $weight: bold, $color: $grey-text);

  .startDateEndDate {
    color: $black;
  }
}

.alignCenter {
  text-align: center;
}

.almostSoldOut {
  display: block;
  margin: -30px var(--view-more-dates-inline-padding) 40px;

  @include font($size: rem(17), $line-height: rem(28), $weight: bold, $color: $grey-text);

  text-align: center;
}

.flyoutContent {
  --view-more-dates-inline-padding: 50px;

  @include media-breakpoint-down(xs) {
    --view-more-dates-inline-padding: 30px;
  }

  .almostSoldOut {
    margin-top: 40px;
    padding-top: 20px;
    text-align: left;
    border-top: 1px dashed $gray-410;
  }
}

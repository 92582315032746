@import '@/_styles/import';
.content {
  position: fixed;
  inset: 0;
  max-width: 530px;
  max-height: 860px;
  margin: auto;
  background-color: transparent;

  &::after {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    inset: unset;
    left: 0;
    bottom: 0;
    right: 0;
    max-width: unset;
    max-height: unset;
  }
}

[data-vaul-drawer][data-vaul-snap-points='false'][data-vaul-drawer-direction='bottom'][data-state='closed'].content {
  animation-name: slideToBottom;
}

@keyframes slideToBottom {
  to {
    transform: translate3d(0, var(--initial-transform, 100%), 0);
    opacity: 0;
  }
}

.overlay {
  backdrop-filter: blur(8px);
}

.closeButton {
  @include reset-button();

  position: absolute;
  z-index: 1;
  top: 55px;
  right: 15px;
  outline-color: $black;
  cursor: pointer;

  :global(.Icon.Close) {
    @include icon($color: $lightgrey2);
  }

  :global(.Icon.Chevron) {
    display: none;
  }

  @include media-breakpoint-down(xs) {
    top: -25px;
    right: 25px;

    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;

    background: $white;
    border-radius: 100%;
    box-shadow:
      0 0 10px rgba($black-deep, 0.2),
      0 0 2px rgba($black-deep, 0.6);

    :global(.Icon.Close) {
      display: none;
    }

    :global(.Icon.Chevron) {
      display: block;

      @include icon($width: 35px, $height: 35px, $color: $vv-red);
    }
  }
}

.box {
  --side-padding: 90px;

  position: relative;
  border-radius: 4px;
  margin: 40px 0;
  height: unset;
  padding: 130px var(--side-padding) 174px;
  background-color: $white;

  &._oneButton {
    padding-bottom: 105px;
  }

  .closeButtonDesktop {
    @include reset-button();

    position: fixed;
    z-index: 1;
    top: 55px;
    right: 15px;
    outline-color: $black;
    cursor: pointer;

    :global(.Icon) {
      @include icon();
    }
  }

  .title {
    @include font($size: rem(20), $color: $black, $line-height: rem(26.4), $weight: 700);

    padding: 0 var(--side-padding) 24px;
    position: fixed;
    background-color: $gray-085;
    border-radius: 4px;
    backdrop-filter: blur(2px);
    width: 100%;
    top: 40px;
    left: 0;
    height: 130px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    .addedToVoyage {
      @include font($size: rem(13), $color: $blue-purple, $line-height: rem(20), $weight: 500);
    }
  }

  .description {
    margin-bottom: 24px;
    display: grid;
    grid-template-areas:
      'thumbnail descriptionText'
      'thumbnail detailsButton';

    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;

    .thumbnail {
      grid-area: thumbnail;
      width: 128px;
      height: 85px;
      display: block;
      border-radius: 8px;
      margin-right: 16px;
      object-fit: contain;
    }

    .descriptionText {
      grid-area: descriptionText;

      @include font($size: rem(14), $line-height: rem(22.96), $weight: 400, $color: $gray-light);

      margin-bottom: 8px;
    }

    .detailsButton {
      grid-area: detailsButton;

      button {
        @include reset-button();
        @include font($size: rem(13), $color: $lightgrey2, $line-height: 20px, $weight: 400);

        text-decoration: underline;
        text-align: left;
        outline-color: $black;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }

        .detailsButtonLabel {
          display: none;
        }

        &[aria-expanded='true'] .detailsButtonLabel[data-opened='true'],
        &[aria-expanded='false'] .detailsButtonLabel[data-opened='false'] {
          display: block;
        }
      }
    }
  }

  .details {
    background-color: $neutral-sea-mist;
    border-radius: 8px;
    padding: 16px;
    margin: 24px 0;

    overflow: hidden;
    transition:
      opacity 0.1s ease-out,
      height 0.3s ease-out,
      padding 0.3s ease-out,
      margin 0.3s ease-out;

    &[data-opened='false'] {
      height: 0;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      opacity: 0;
    }

    @include font($size: rem(14), $line-height: rem(24), $weight: 400, $color: $gray-light);

    .needToKnowTitle {
      @include font($size: rem(15), $color: $black, $line-height: rem(24), $weight: 500);

      margin-top: 20px;
    }

    .needToKnow {
      .needToKnowItem {
        margin: 8px 0;
        display: flex;

        :global(.Icon) {
          @include icon();
        }
      }
    }
  }

  .price {
    display: flex;
    justify-content: space-between;

    @include font($size: rem(17), $color: $black, $line-height: rem(22.44), $weight: 700);

    border-top: 1px solid $gray-010;
    padding: 16px 0;
  }

  .buttons {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 10px var(--side-padding) 24px;
    background-color: $white;

    border-radius: 0 0 4px 4px;

    &::before {
      pointer-events: none;
      content: '';

      position: absolute;
      z-index: 9999;
      top: -30px;
      right: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 30px;

      background: linear-gradient(0deg, $white, transparent);
    }

    .cancelButton,
    .removeButton {
      @include reset-button();
      @include font($size: rem(14), $color: $lightgrey2, $line-height: 48px, $weight: 500);

      text-decoration: underline;
      outline-color: $black;
      text-align: center;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    --side-padding: 24px;

    max-height: 80vh;
    border-radius: 4px 4px 0 0;
    padding-top: 115px;
    margin: unset;

    .title {
      @include font($size: rem(16), $color: $black, $line-height: rem(21.12), $weight: 700);

      padding-top: 0;
      height: 115px;
      top: 0;
    }

    .buttons {
      bottom: 0;
      border-radius: 0;
    }
  }
}
